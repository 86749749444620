import { typeUtil } from '@beecode/msh-node-util/lib/type-util'
import { ReactElement } from 'react'
import { CvSectionBulletPoints } from 'src/component/cv/section/bullet-points'
import { CvSectionParagraphs } from 'src/component/cv/section/paragraps'
import { CvSectionSimpleText } from 'src/component/cv/section/simple-text'
import { CvSectionTags } from 'src/component/cv/section/tags'
import { SectionModel, SectionPayload, SectionType } from 'src/model/section-model'

export const CvSectionStrategy = <T extends SectionType = never>(props: { section: SectionModel<T> }): ReactElement => {
  const { section } = props
  switch (section.strategy) {
    case SectionType.SIMPLE_TEXT:
      return <CvSectionSimpleText payload={section.payload as SectionPayload<SectionType.SIMPLE_TEXT>} />
    case SectionType.PARAGRAPHS:
      return <CvSectionParagraphs payload={section.payload as SectionPayload<SectionType.PARAGRAPHS>} />
    case SectionType.TAGS:
      return <CvSectionTags payload={section.payload as SectionPayload<SectionType.TAGS>} />
    case SectionType.BULLET_POINTS:
      return <CvSectionBulletPoints payload={section.payload as SectionPayload<SectionType.BULLET_POINTS>} />
    default:
      console.error(typeUtil.exhaustiveMessage(`Unknown section type: ${section.strategy}`, section.strategy)) // eslint-disable-line no-console
      return <></>
  }
}
