import { UrlLink } from 'src/model/person-model'

export enum SectionType {
  SIMPLE_TEXT = 'SIMPLE_TEXT',
  PARAGRAPHS = 'PARAGRAPHS',
  TAGS = 'TAGS',
  BULLET_POINTS = 'BULLET_POINTS',
}

// prettier-ignore
export type SectionPayload<T extends SectionType> =
    T extends SectionType.SIMPLE_TEXT ? SectionTypeSimpleTextPayload
  : T extends SectionType.PARAGRAPHS ? SectionTypeParagraphsPayload
  : T extends SectionType.TAGS ? SectionTypeTagsPayload
  : T extends SectionType.BULLET_POINTS ? SectionTypeBulletPointsPayload
  : never

export type SectionTypeSimpleTextPayload = { text: string }

export type Paragraph = {
  title: string
  webSite?: UrlLink
  descriptionPoints?: string[]
  startDate?: string
  endDate?: string
  image?: string
  subTitle?: string
}
export type SectionTypeParagraphsPayload = { paragraphs: Paragraph[] }

export type Tag = { name: string; group: string }
export type SectionTypeTagsPayload = { tags: Tag[] }

export type SectionTypeBulletPointsPayload = { bulletPoints: string[] }

export type SectionModel<T extends SectionType = never> = {
  title: string
  strategy: T
  payload: SectionPayload<T>
}
