import { FC, useCallback, useEffect, useState } from 'react'
import { CvTemplate } from 'src/component/cv/template'
import { Paper } from 'src/component/paper'
import { Spinner } from 'src/component/util/spinner'
import { PersonModel, personModelFactory } from 'src/model/person-model'
import { personService } from 'src/service/person-service'

export const CvScreen: FC = () => {
  const [person, setPerson] = useState<PersonModel>()
  const [isLoading, setIsLoading] = useState(false)

  const getPersonData = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)
      const personData = await personService.getPerson()
      setPerson(personModelFactory(personData))
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getPersonData().catch((err) => console.error(err)) // eslint-disable-line no-console
  }, [getPersonData])

  useEffect(() => {
    document.title = [person?.FullName, 'CV'].filter(Boolean).join(' - ')
  }, [person])

  if (isLoading || !person) return <Spinner />
  return (
    <Paper>
      <CvTemplate person={person} />
    </Paper>
  )
}
