import styles from './bullet-points.module.scss'
import React, { FC } from 'react'
import { SectionPayload, SectionType } from 'src/model/section-model'

export const CvSectionBulletPoints: FC<{ payload: SectionPayload<SectionType.BULLET_POINTS> }> = (props) => {
  const { bulletPoints } = props.payload

  return (
    <ul className={`print-keep-together ${styles.container}`}>
      {bulletPoints.map((point, ix) => {
        return (
          <li key={ix}>
            <span>{point}</span>
          </li>
        )
      })}
    </ul>
  )
}
