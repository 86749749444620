import { SectionModel } from 'src/model/section-model'
import { dateTimeUtilFactory } from 'src/util/dateTimeUtil'

export type UrlLink = { url: string; text: string }

export class PersonModel {
  emailAddress!: string
  firstName!: string
  lastName!: string
  title!: string
  birthDate?: string
  phoneNumber?: string
  webSite?: UrlLink
  sections: SectionModel[] = []

  get FullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  get YearsOld(): number | undefined {
    if (!this.birthDate) return undefined
    const dateTimeUtil = dateTimeUtilFactory()
    return dateTimeUtil.yearCountSince(dateTimeUtil.toDate(this.birthDate))
  }

  constructor(partialEntity: Partial<PersonModel>) {
    if (!partialEntity) return

    const propKeys: (keyof PersonModel)[] = [
      'firstName',
      'lastName',
      'title',
      'birthDate',
      'phoneNumber',
      'emailAddress',
      'webSite',
      'sections',
    ]
    propKeys.forEach((key) => {
      if (partialEntity[key] === undefined) {
        delete this[key]
      } else {
        ;(this as any)[key] = partialEntity[key] as any
      }
    })
  }
}

export const personModelFactory = (data: PersonModel): PersonModel => new PersonModel(data)
