import { FC, Fragment, useMemo } from 'react'
import { ThemeWebSiteLink } from 'src/component/theme/web-site-link'
import { Paragraph } from 'src/model/section-model'
import { dateTimeUtilFactory } from 'src/util/dateTimeUtil'

const formatDate = (dateString?: string): string => {
  const dateTimeUtil = dateTimeUtilFactory()

  return dateString ? dateTimeUtil.formatMonthYear(dateTimeUtil.toDate(dateString)) : 'ongoing'
}

export const workedFor = (startDate: string, endDate?: string): string => {
  const dateTimeUtil = dateTimeUtilFactory()
  const dateFrom = dateTimeUtil.toDate(startDate)
  const dateTo = dateTimeUtil.endOfMonth(dateTimeUtil.toDate(endDate || dateTimeUtil.now()))
  const monthsTotal = dateTimeUtil.monthCountBetweenDates(dateTo, dateFrom)
  const years = Math.floor(monthsTotal / 12)
  const months = monthsTotal % 12
  const words = []
  years > 0 && words.push(`${years} y`)
  months > 0 && words.push(`${months} m`)
  return words.join(', ')
}

export const CvSectionParagraph: FC<{ paragraph: Paragraph }> = (props) => {
  const { paragraph } = props

  const subTitleText = useMemo(() => {
    const subTitleTexts = []
    if (paragraph.subTitle) subTitleTexts.push(paragraph.subTitle)
    if (paragraph.startDate) {
      subTitleTexts.push(
        `${formatDate(paragraph.startDate)} - ${formatDate(paragraph.endDate)} (${workedFor(
          paragraph.startDate,
          paragraph.endDate,
        )})`,
      )
    }
    return subTitleTexts.join(' ')
  }, [paragraph])

  return (
    <div className="print-keep-together">
      {paragraph.image && <img className="float-right" width={56} src={paragraph.image} alt="section image" />}
      <h2> {paragraph.title}</h2>
      {subTitleText && <h3>{subTitleText}</h3>}
      <p>
        {paragraph.descriptionPoints &&
          paragraph.descriptionPoints.map((dp: any, ix: number) => {
            return (
              <Fragment key={ix}>
                <span key={ix}>{dp}</span>
                <br />
              </Fragment>
            )
          })}
        <ThemeWebSiteLink webSite={paragraph.webSite} />
      </p>
    </div>
  )
}
