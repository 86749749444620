import { FC } from 'react'
import Gravatar from 'react-gravatar'
import { ThemeWebSiteLink } from 'src/component/theme/web-site-link'
import { PersonModel } from 'src/model/person-model'

export const CvHeader: FC<{ person: PersonModel }> = (props) => {
  const { person } = props

  return (
    <header>
      <div className="avatar">
        <Gravatar email={person.emailAddress} size={100} />
      </div>

      <div className="name">
        <h1>
          {person.FullName}
          <small> ({person.YearsOld}y)</small>
        </h1>
        <h2>{person.title}</h2>
      </div>

      <ul className="contact-details">
        <li>e: {person.emailAddress}</li>
        <ThemeWebSiteLink webSite={person.webSite} wrapperTag="li" />
        {person.phoneNumber && <li>m: {person.phoneNumber}</li>}
      </ul>
      <div className="clear" />
    </header>
  )
}
