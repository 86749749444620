import styles from './tags.module.scss'
import { FC } from 'react'
import { SectionPayload, SectionType } from 'src/model/section-model'

export const CvSectionTags: FC<{ payload: SectionPayload<SectionType.TAGS> }> = (props) => {
  const { tags } = props.payload

  return (
    <div className={`print-keep-together ${styles.tagContainer}`}>
      {tags.map((tag, ix) => {
        return (
          <div className={styles.tag} key={ix}>
            {tag.name}
          </div>
        )
      })}
    </div>
  )
}
