/* eslint-disable */
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { App } from 'src/app'
import reportWebVitals from 'src/reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'src/asset/scss/global.scss'
import 'src/asset/scss/cv.scss'
/* eslint-enable */

ReactDOM.render(
  <StrictMode>
    {/*<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>*/}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
