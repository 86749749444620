import { FC } from 'react'
import { CvHeader } from 'src/component/cv/header'
import { CvSection } from 'src/component/cv/section'
import { PersonModel } from 'src/model/person-model'
import { SectionModel } from 'src/model/section-model'

export const CvTemplate: FC<{ person: PersonModel }> = (props) => {
  const { person } = props

  return (
    <article className="cv">
      <CvHeader person={person} />
      {person.sections.map((section: SectionModel, ix: number) => {
        return <CvSection key={ix} section={section} />
      })}
    </article>
  )
}
