import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CvScreen } from 'src/screen/cv-screen'

export const Router: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<CvScreen />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}
