import { FC } from 'react'
import { CvSectionParagraph } from 'src/component/cv/section/paragrap'
import { SectionPayload, SectionType } from 'src/model/section-model'

export const CvSectionParagraphs: FC<{ payload: SectionPayload<SectionType.PARAGRAPHS> }> = (props) => {
  const { payload } = props
  return (
    <article>
      {payload.paragraphs.map((paragraph, ix) => (
        <CvSectionParagraph key={ix} paragraph={paragraph} />
      ))}
    </article>
  )
}
