import { FC } from 'react'
import { CvSectionStrategy } from 'src/component/cv/section/strategy'
import { SectionModel } from 'src/model/section-model'

export const CvSection: FC<{ section: SectionModel }> = (props) => {
  const { section } = props

  return (
    <section>
      <div className="section-title">
        <h1>{section.title}</h1>
      </div>
      <div className="section-content">
        <CvSectionStrategy section={section} />
      </div>
      <div className="clear" />
    </section>
  )
}
