import { differenceInMonths, differenceInYears, endOfMonth, format } from 'date-fns'

export class DateTimeUtil {
  now(): Date {
    return new Date()
  }

  toDate(date: number | string | Date): Date {
    return new Date(date)
  }

  endOfMonth(date: Date): Date {
    return endOfMonth(date)
  }
  monthCountBetweenDates(fromDate: Date, toDate: Date): number {
    return differenceInMonths(fromDate, toDate) + 1
  }
  yearCountSince(date: Date): number {
    return differenceInYears(this.now(), date)
  }

  formatMonthYear(date: Date): string {
    return format(date, 'MMM yyyy')
  }
}

export const dateTimeUtilFactory = (): DateTimeUtil => new DateTimeUtil()
